import { PageProps } from 'gatsby';
import { EspaceData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Espace';
import CMSView from '~/components/View';
import List from '~/containers/Admin/List';
import params from '~/params/espace.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

const AdminEspaces: FC<PageProps & UserProps> = ({ location, user }) => {
  const { t } = useTranslation();

  return (
    <TemplateAdmin>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix="/admin/espaces/"
          model={new EspaceData({ params })}
          search={location.search}
          title={t('espaces.title')}
          user={user}
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(AdminEspaces, 'admin');

import { DocumentType, EspaceType } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import findByEspaceId from '~/actions/espace/findById';
import { ListItemProps } from '~/containers/Admin/props';
import IconExternalLinkAlt from '~/icons/ExternalLinkAlt';

import ListItem from './index';

const ListItemEspace: FC<ListItemProps<EspaceType>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const dispatch = useDispatch();
  const [doc, setDoc] = useState<DocumentType<EspaceType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  const handleChangeEspace = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    await dispatch(findByEspaceId(docId));

    return navigate(`/espaces/${docId}/dashboard/`);
  };

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      actions={[
        {
          icon: IconExternalLinkAlt,
          label: "Afficher l'espace de travail",
          onClick: handleChangeEspace,
        },
      ]}
      displayActionId
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemEspace;
